
$(document).ready(function () {
$('#edition2015').on('click',function(){
    $('#img2014').addClass('hidden');
    $('#img2015').removeClass('hidden');
});
	$('#edition2014').on('click',function(){
    $('#img2015').addClass('hidden');
    $('#img2014').removeClass('hidden');
});

   
$('.my-dropdown > a').append("<i class='fa fa-angle-down pull-right fa-2x'></i>");
$('.my-dropdown a').on('click',function(){
   var self = $(this)
    self.toggleClass('active').next().toggleClass('active');
    $(self[0]).children().toggleClass('fa-angle-down fa-angle-up');
});
    

$("a[href='#about-company']").click(function() {
	$("html, body").animate({scrollTop: $('#about-company').offset().top }, 2000);
  return false;
});	
	
$(".btn-scrollTop").click(function() {
	$("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
});

$(window).on("scroll", function(){
	if( $(window).scrollTop() > 15){
	$(".btn-scrollTop").fadeIn("slow");
	}
	else $(".btn-scrollTop").fadeOut();
	});


    $(".my-dropdown").on("mouseenter", function () {
        $(this).children(".my-dropdown-menu").removeClass("hidden");
    });

    var mainslider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
        dots: false,
        focusOnSelect: true,
        asNavFor: '.tab-slider',
        centerMode: false,
        arrows: false,
        autoplay: true,
        infinite: true
    };
    var tabslider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.main-slider',
        arrows: false,
        fade: true,
        infinite: true
    };




    $('.main-slider').on('init', function (e, slick) {
        var $firstAnimatingElements = $('div.item:first-child').find('[data-animation]');
        doAnimations($firstAnimatingElements);
    });
    $('.main-slider').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        
        var $animatingElements = $('div.item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
        doAnimations($animatingElements);
        $($(".animateMe")[currentSlide]).addClass("resetMe");
        //$($(".animateMe")[nextSlide]).removeClass("resetMe");
        console.log('im changing');
        
    });
    $('.main-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        // finally let's do this after changing slides
        $($(".animateMe")[currentSlide]).removeClass("resetMe");
        var $animRemoveCurrent = $('div.item[data-slick-index="' + currentSlide + '"]').find("[data-remove]");
        
         removeAnimations($animRemoveCurrent);
        console.log('after change');
    });

    $('.main-slider').slick(mainslider);
    $('.tab-slider').slick(tabslider);

    function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function () {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function () {
                $this.removeClass($animationType);
            });
        });
    }
    function removeAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function () {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('remove');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function () {
                $this.removeClass($animationType);
            });
        });
    }




    //scrollMagic


    var stFun = function () {
        $(".circle-img").removeClass("spinAnim");
        $(".counter").html("");
    }

    var myFun = function () {
        $(".circle-img").addClass("spinAnim");
        $('.counter').each(function () {
            var $this = $(this),
                countTo = $this.attr('data-count');

            $({ countNum: $this.text() }).animate({
                countNum: countTo
            },

            {

                duration: 2200,
                easing: 'linear',
                step: function () {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                    $this.text(this.countNum);
                    //alert('finished');
                }

            });



        });
    }



    var controller = new ScrollMagic.Controller();

    var feature = $(".features");

    var feature_box = $(".feature-box");

    var op_list = $(".op-list li");
    var mu_section = $(".mu-dashboard");

    var mdv_section = $(".mdvff");
    var mdv_heading = $("#mdv-heading ");
    var points_list = $(".mdvpoints-list li");
    


    var provider_tween = TweenMax.fromTo("#customizable", 2, { left: -522, opacity: 0 }, { left: 0, opacity: 1, onStart: stFun, onComplete: myFun });

    var patient_tween = TweenMax.fromTo("#affordable", 2, { bottom: -522, opacity: 0 }, { bottom: 0, opacity: 1 });
    var valuable_tween = TweenMax.fromTo("#scalable", 2, { bottom: -522, opacity: 0 }, { bottom: 0, opacity: 1 });
    var location_tween = TweenMax.fromTo("#valuable", 2, { right: -522, opacity: 0 }, { right: 0, opacity: 1 });

    var boxes1_tl = new TimelineLite();

    boxes1_tl.staggerFrom(feature_box, 1.2, { ease: Expo.easeInOut, x: "-100%", opacity: 0 }, 0.1);

    //mu tween

    var op_list_tl = new TimelineLite();
    op_list_tl.staggerFrom(op_list, 2, { top: -522, opacity: 0 }, { top: 0, opacity: 1 });

    var mu_tab_tween = TweenMax.fromTo('.tab-container', 1, { marginTop: 522, opacity: 0 }, { marginTop: "7.15em", opacity: 1 });

    //mdv tween
    var mdv_heading_tween = TweenMax.from(mdv_heading, 2, { ease: Bounce.easeOut, y: -200 });

    //var points_list_tl = new TimelineMax();
    //points_list_tl.staggerFrom(points_list, 1.2, { translateX: "-121%", opacity: 0, ease: Elastic.easeOut.config(1.1, 0.4) }, 0.1);

    var points_list_tl = TweenMax.staggerFrom(points_list, 0.2, { css: { transform: "translateX(-630px)" }, opacity: 0, delay: 0.5, ease: Back.easeOut }, 0.2);

    // var patient_tab1_tween = TweenMax.staggerFrom(".tabimg", 1,{className:"+=active"} , 0.1);

    var zIndexCh = TweenMax.from(".tab-outer", 0.6, { css: { zIndex: "5" },delay: 0.2 } );

    var provider_scene = new ScrollMagic.Scene({
        triggerElement: feature,
        duration: 480,
    }).setTween(provider_tween);

    var patient_scene = new ScrollMagic.Scene({
        triggerElement: feature,
        duration: 520,
    }).setTween(patient_tween);
var valuable_scene = new ScrollMagic.Scene({
        triggerElement: feature,
        duration: 520,
    }).setTween(valuable_tween);
    var location_scene = new ScrollMagic.Scene({
        triggerElement: feature,
        duration: 530,
    }).setTween(location_tween);

    var boxes1_scene = new ScrollMagic.Scene({
        triggerElement: "#valuable",
        duration: 0
    }).setTween(boxes1_tl);


    //mu section

    var mu_scene = new ScrollMagic.Scene({
        triggerElement: ".mu-dashboard",
        duration: 0
    }).setTween(op_list_tl);

    var mu_tab_scene = new ScrollMagic.Scene({
        triggerElement: ".mu-dashboard",
        duration: 0
    }).offset(0).setTween(mu_tab_tween);

    //mdv

    var mdv_heading_scene = new ScrollMagic.Scene({
        triggerElement: ".mdvision",
        duration: 0
    }).offset(50).setTween(mdv_heading_tween);

    var points_list_scene = new ScrollMagic.Scene({
        triggerElement: ".mdvision",
        duration: 0
    }).offset(90).setTween(points_list_tl);


    //patient

    var patient_tab_scene = new ScrollMagic.Scene({
        triggerElement: ".patient-checkin",
        duration: 0
    }).offset(200).setClassToggle(".tabimg", "active").setTween(zIndexCh);


    controller.addScene([
        provider_scene,
        patient_scene,
		valuable_scene,
        location_scene,
        boxes1_scene,
        mu_scene,
        mu_tab_scene,
        mdv_heading_scene,
        points_list_scene,
        patient_tab_scene
    ]);








    //mu dashboard
    var $hoverMe = $('.az');
    var $colorOverlay = $('.color-overlay');
    var $whiteOverlay = $('.active-tt');
    var isAnimated = false;

    $hoverMe.on('mouseenter', showImage);
    $hoverMe.on('mouseleave', hideImage);

    $(".op-list .border-line-ef").on('mouseenter', function () {
        var targetEl = $(this).data().target;
        $(".mu-db img").addClass("hidden").removeClass("active-t");
        $(".mu-db img").removeClass("hidden");
        $(targetEl).removeClass("hidden").addClass("active-t");
        //console.log(this);
        //$('.white-overlay').css({ "background": " url(" + $(targetEl).attr("src") + ") no-repeat", "background-size": "cover" });
        showImage();

    });
    $(".op-list .border-line-ef").on('mouseleave', function () {
        //$(".mu-db img").addClass("hidden");
        var targetEl = $(this).data().target;
        $(targetEl).removeClass("hidden");
        // transform-origin: 100%;
        // transform: scaleX(0.1);
        // $('.white-overlay').css({ "background": " url(" + $(targetEl).attr("src") + ") no-repeat", "background-size": "cover" });
        //hideImage();

        if (isAnimated) {
            //TweenMax.killAll();
            $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
            isAnimated = false;
        } else {
            isAnimated = true;
        }

        TweenMax.to($whiteOverlay, 1,
            { scaleX: 0, ease: Quart.easeInOut }
        );
        $('.mu-db img').css("transform-origin","0");
        // TweenMax.to('..active-t', 1,
        //     { scaleX: 0, ease: Quart.easeInOut }
        // );

    });

    function showImage() {
        if (isAnimated) {
            //TweenMax.killAll();
            $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
            isAnimated = false;
        } else {
            isAnimated = true;
        }
        
        TweenMax.fromTo('.active-t', 1,
            { scaleX: 0, ease: Quart.easeInOut },{scaleX: 1, ease: Quart.easeInOut, onComplete: function () {
                        isAnimated = false;
                    }}
        );
        // TweenMax.fromTo($colorOverlay, 1,
        //     { scaleX: 1 },
        //     {
        //         scaleX: 0, ease: Quart.easeInOut, delay: 0.2, onComplete: function () {
        //             $whiteOverlay[0].style.transformOrigin = "left 50% 0px";
        //             isAnimated = false;
        //         }
        //     }
        // );
    }

    function hideImage() {
        if (isAnimated) {
            //TweenMax.killAll();
            isAnimated = false;
            $whiteOverlay[0].style.transformOrigin = "right";
        } else {
            isAnimated = true;
        }

        TweenMax.to($whiteOverlay, 0.5, {
            scaleX: 1,
            ease: Quart.easeInOut,
            onComplete: function () {
                $whiteOverlay[0].style.transformOrigin = "right";
                isAnimated = false;
            }
        });
    }

});

$("#sbt").click(function(){
    
        var frm = $(this).closest('form').attr('id');
        var validator = $('#'+frm).bootstrapValidator('validate');
    if ($('#'+frm).data('bootstrapValidator').isValid()) {
            fetchFormData(frm);
        }
    });

//send data service
var fetchFormData = function(ev){
    var $frm = $('#'+ev);

        $captcha = $( '#recaptcha' );
        response = grecaptcha.getResponse();
    if(response.length !== 0){
        
  // send ajax
  $.ajax({
        url: 'postMail.php', // url where to submit the request
        type : "POST", // type of action POST || GET
        data : $frm.serialize(), // post data || get data
        success : function(result) {
            $frm.append('<div class="well well-lg well-success">Thank you for your interest in MD Vision, someone will get back to you shortly</div>' );
            $frm.find(".form-control").val("");
            $frm.data('bootstrapValidator').resetForm();
            grecaptcha.reset();
        },
        error: function(xhr, resp, text) {
            $frm.append('<div class="well well-lg well-error">'+ resp +'</div>');
            $frm.find(".form-control").val("");
            $frm.data('bootstrapValidator').resetForm();
            grecaptcha.reset();
        }
    });
    
    // form submit return true
             return true;
         } 
         else{
            $( '.msg-error').text( "Captcha Error..." );
// form submit return false
             return false;
         }


          
    }


    $("#sbt-channel").click(function(){
    
        var frm = $(this).closest('form').attr('id');
        var validator = $('#'+frm).bootstrapValidator('validate');
    if ($('#'+frm).data('bootstrapValidator').isValid()) {
            fetchFormData1(frm);
        }
    });

//send data service
var fetchFormData1 = function(ev){
    var $frm = $('#'+ev);

        $captcha = $( '#recaptcha' );
        response = grecaptcha.getResponse();
    if(response.length !== 0){
        // alert('send');
              // send ajax
              // $.ajax({
              //       url: 'postMail.php', // url where to submit the request
              //       type : "POST", // type of action POST || GET
              //       data : $frm.serialize(), // post data || get data
              //       success : function(result) {
              //           $frm.append('<div class="well well-lg well-success">Thank you for your interest in MD Vision, someone will get back to you shortly</div>' );
              //           $frm.find(".form-control").val("");
              //           $frm.data('bootstrapValidator').resetForm();
              //           grecaptcha.reset();
              //       },
              //       error: function(xhr, resp, text) {
              //           $frm.append('<div class="well well-lg well-error">'+ resp +'</div>');
              //           $frm.find(".form-control").val("");
              //           $frm.data('bootstrapValidator').resetForm();
              //           grecaptcha.reset();
              //       }
              //   });
                
                // form submit return true
             return true;
         } 
         else{
            $( '.msg-error').text( "Captcha Error..." );
// form submit return false
             return false;
         }


          
    }